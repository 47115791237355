<template>
  <div class="withdraw f-w3">
    <div
      class="label d-flex justify-content-center f-w6"
      v-if="route !== 'CastMyPageBdHistory'"
    >
      1,000P以上を入力してください
    </div>
    <div class="label d-flex justify-content-center f-w6" v-else>
      1,000コイン以上を入力してください
    </div>
    <div
      id="money-wrap"
      class="d-flex align-items-center justify-content-center _money"
      @click="triggerFocus"
    >
      <money
        v-model="point"
        v-bind="money"
        @input="checkValue"
        class="input-money f-w3"
        autofocus
      ></money>
      <span class="f-w3"
        ><span v-if="point > 0">,000</span>{{ lastLabelPoint }}</span
      >
    </div>
    <button @click="withdrawal">確定する</button>
  </div>
</template>

<script>
import { Money } from "v-money";
import { mapGetters } from "vuex";
export default {
  components: {
    Money
  },
  props: {
    totalPoint: String
  },
  name: "WithDraw",
  data() {
    return {
      point: 0,
      lastLabelPoint:
        this.$route.name !== "CastMyPageBdHistory" ? "P" : "コイン",
      money: {
        decimal: ",",
        thousands: ",",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */
      },
      route: this.$route.name
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
  watch: {
    point: function() {
      let inputMoney = document.querySelector(".input-money");
      if (inputMoney) {
        if (parseInt(inputMoney.value.replace(/,+/g, "")) > 9999999) {
          this.point = inputMoney.value
            .replace(/,+/g, "")
            .toString()
            .slice(0, inputMoney.value.replace(/,+/g, "").length - 1);
        }
      }
      if (inputMoney <= 0) {
        this.point == 0;
      }
    }
  },
  mounted() {
    let body = document.querySelector("body");
    body.addEventListener("keypress", this.removeNegative);
  },
  methods: {
    triggerFocus() {
      let inputMoney = document.querySelector(".input-money");
      if (inputMoney) {
        inputMoney.focus();
      }
    },
    withdrawal() {
      let total = this.totalPoint;
      if (this.$route.name !== "CastMyPageBdHistory") {
        if (this.point * 1000 < 1000) {
          this.$toast(
            "1000ポイント以上で指定してください。",
            "不合格",
            "danger"
          );
        } else if (this.point * 1000 > parseInt(total)) {
          this.$toast("ポイント残高内で入力してください。", "不合格", "danger");
        } else {
          let redirectUrl = "CastMyPageWithdrawalPG";
          if (this.user.sex === 1) {
            redirectUrl = "MenMyPageWithdrawalPG";
          }
          localStorage.setItem("point", this.point * 1000);
          this.$root.$refs.loading.start();
          this.$router.push({
            name: redirectUrl
          });
          this.$root.$refs.loading.finish();
        }
      } else {
        if (this.point * 1000 < 1000) {
          this.$toast("1000コイン以上を指定してください。", "不合格", "danger");
        } else if (this.point * 1000 > parseInt(total)) {
          this.$toast("ポイント残高内で入力してください。", "不合格", "danger");
        } else {
          localStorage.setItem("point", this.point * 1000);
          this.$root.$refs.loading.start();
          this.$router.push({
            name: "CastMyPageWithdrawal"
          });
          this.$root.$refs.loading.finish();
        }
      }
    },
    // convert negative to absolute number
    checkValue() {
      let moneyWrap = document.getElementById("money-wrap");
      let inputMoney = document.querySelector(".input-money");
      if (moneyWrap) {
        if (moneyWrap.offsetWidth === 221) {
          if (inputMoney) {
            if (inputMoney.value.length > 1) {
              inputMoney.style.width = (inputMoney.value.length + 3) * 9 + "px";
            } else if (inputMoney.value.length == 1) {
              inputMoney.style.width = 28 + "px";
            }
          }
        } else {
          if (inputMoney) {
            if (inputMoney.value.length > 1) {
              inputMoney.style.width =
                (inputMoney.value.length + 3) * 12 + "px";
            } else if (inputMoney.value.length == 1) {
              inputMoney.style.width = 30 + "px";
            }
          }
        }
      }
      if (this.point < 0) {
        this.point = Math.abs(this.point);
      }
    },
    // not allow '-'
    removeNegative(e) {
      if (e.key === "-") {
        e.preventDefault();
      }
    }
  },
  beforeDestroy() {
    let body = document.querySelector("body");
    body.removeEventListener("keypress", this.removeNegative);
  }
};
</script>

<style scoped lang="scss">
.withdraw {
  width: 100%;
  padding: 39px 0 13px 0;
  margin: 0 -1px;
  ._money {
    border: 1px solid #bebebe;
    height: 40px;
    width: 221px;
    overflow: hidden;
    margin: 0 auto;
    margin-top: 20px;
    border-radius: 5px;
    text-align: center;
    line-height: 40px;
    input {
      font-size: 20px;
      line-height: 25px;
      height: 40px;
      color: #000000;
      text-align: right;
      padding: 0;
      // padding-right: 5px;
      width: 28px;
    }
    span {
      display: inline-block;
      font-size: 20px;
      line-height: 25px;
      margin-right: 0;
    }
  }
}
.label {
  font-size: 16px;
  color: #000000;
  line-height: 21px;
}

button {
  margin: 35px auto 0;
  display: block;
  width: 337px;
  height: 45px;
  border-radius: 5px;
  background-color: #464d77;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #ffffff;
  line-height: 20px;
}

@media only screen and (min-width: 1079px) {
  .withdraw {
    width: 482px;
    padding: 39px 20px 27px 20px;
    margin: 0 -1px;

    ._money {
      border: 1px solid #bebebe;
      height: 60px;
      width: 285px;
      overflow: hidden;
      margin: 0 auto;
      margin-top: 25px;
      border-radius: 5px;
      text-align: center;
      input {
        width: 30px;
        font-size: 24px;
        line-height: 32px;
        color: #000000;
        text-align: right;
      }
      span {
        display: inline-block;
        font-size: 24px;
        line-height: 32px;
        margin-right: 5px;
      }
    }
  }
  .label {
    font-size: 20px;
    color: #000000;
    line-height: 26px;
  }
  button {
    margin-top: 35px;
    display: block;
    width: 100%;
    height: 60px;
    border-radius: 5px;
    background-color: #464d77;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #ffffff;
    line-height: 25px;
  }
}
</style>
