<template>
  <div class="detail-receiption f-w6">
    <p class="text-center detail-receiption__title">領収書の設定</p>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)" class="form text-left">
        <label>宛名</label>
        <ValidationProvider name="宛名" rules="required" v-slot="{ errors }">
          <div class="receiption-note">
            <input
              type="text"
              class="f-w3"
              v-model="data.name"
              @blur="formatString()"
            />
            <p class="f-w3 detail-receiption-note">
              ※割り勘の場合は、「、」か「,」で区切ってください。
            </p>
            <span class="error f-w3 text-left">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
        <label>人数</label>
        <ValidationProvider
          name="人数"
          rules="required|integer"
          v-slot="{ errors }"
        >
          <div class="number-input">
            <input
              type="tel"
              min="0"
              class="f-w3"
              v-model="data.numberPeople"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            />
            <span class="error f-w3 text-left">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
        <div class="note f-w3">
          割り勘の人数によって、小数点以下の端数は切り捨てた領収書になってしまうことを確認の上、ご了承ください。
        </div>
        <div class="row align-items-center">
          <b-col class="label-title" cols="3">但書</b-col>
          <b-col cols="9" class="f-w3 prego">PreGo 利用料</b-col>
        </div>
        <div class="row align-items-center">
          <b-col cols="3" class="label-title date">日付表示</b-col>
          <b-col cols="9" class="switch-wrap">
            <label class="switch">
              <input type="checkbox" v-model="data.status" />
              <span class="slider round"></span>
            </label>
          </b-col>
        </div>
        <button type="submit">確定する</button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: "DetailReceiption",
  props: {
    item: Object
  },
  data() {
    return {
      data: {
        numberPeople: null,
        name: null,
        subStringName: null,
        status: true
      },
      listDataExport: []
    };
  },
  methods: {
    formatString() {
      if (this.data.name !== null) {
        this.data.name = this.data.name.replace(/\s/g, "");
        this.data.name = this.data.name.replace(/、/g, ",");
        this.data.name = this.data.name.replace(/,+/g, "、");
        this.data.name = this.data.name.replace(
          /[`~!@#$%^&*()_|+\-=?;:'".<>/]/gi,
          ""
        );
        var firstIndex = this.data.name.substr(0, 1);
        var lastIndex = this.data.name.substr(this.data.name.length - 1);
        if (firstIndex === "," || firstIndex === "、") {
          this.data.name = this.data.name.substr(1);
        }
        if (lastIndex === "," || lastIndex === "、") {
          this.data.name = this.data.name.substr(0, this.data.name.length - 1);
        }
      }
    },
    onSubmit() {
      this.data.subStringName = this.data.name.split("、");
      if (this.data.subStringName.length == this.data.numberPeople) {
        for (let index = 0; index < this.data.subStringName.length; index++) {
          this.listDataExport.push({
            name: this.data.subStringName[index],
            status: this.data.status,
            item: this.item
          });
        }
        this.$emit("downloadPdf", this.listDataExport);
      } else {
        this.$toast("宛名数と人数が異なります。", "不合格", "danger");
      }
    }
  }
};
</script>

<style lang="scss">
@import "../assets/scss/_fontFamily.scss";
@import "../assets/scss/detailReceiption.scss";
</style>
