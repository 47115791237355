<template>
  <div class="text-center pdf-download f-w3">
    <img class="img-pdf" src="@/assets/image/pdf.svg" />
    <br />
    <p class="pdf-download__p f-w3">{{ fileName }}</p>
    <button
      class="pdf-download__button pdf-download__button-download"
      @click="exportPdf()"
    >
      <img src="@/assets/image/icon-download.svg" alt="" />
      ダウンロード
    </button>
    <button class="pdf-download__button" @click="cancel()">キャンセル</button>
  </div>
</template>

<script>
import FileSave from "file-saver";

export default {
  components: {},
  props: {
    data: Array
  },
  data() {
    return {
      fileName: "領収書.pdf",
      dataExport: this.data
    };
  },

  methods: {
    async exportPdf() {
      this.$root.$refs.loading.start();
      await this.$store
        .dispatch("orderManagement/export", this.dataExport)
        .then(response => {
          let blob = new Blob([response], { type: "application/pdf" });
          FileSave.saveAs(blob, "Prego.pdf");
          this.$root.$refs.loading.finish();
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
      this.dataExport = null;
      this.cancel();
    },
    cancel() {
      this.dataExport = null;
      this.$emit("cancelDownloadPDF");
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/_fontFamily.scss";
@import "@/assets/scss/_variables.scss";
.pdf-download {
  width: 337px;
  padding: 0;
  .img-pdf {
    margin-top: 25px;
  }
  &__p {
    color: #464d77;
    margin-top: 21px;
    margin-bottom: 35px;
    font-size: 16px;
    line-height: 20px;
  }
  &__button {
    width: 100%;
    height: 45px;
    outline: none;
    border: none;
    box-shadow: none;
    border-radius: 5px;
    margin-bottom: 25.14px;
    background-color: #ececec;
    color: $black;
    font-size: 16px;
    line-height: 20px;
    &-download {
      img {
        margin-right: 15px;
      }
      margin-bottom: 15px;
      background-color: #464d77;
      color: $white;
    }
  }
}

@media only screen and (min-width: 1079px) {
  .pdf-download {
    width: 480px;
    padding: 0 20px;
    .img-pdf {
      margin-top: 45px;
      width: 113.19px;
      height: 129.36px;
    }
    &__p {
      color: #464d77;
      margin-top: 25.64px;
      margin-bottom: 49px;
      font-size: 16px;
      line-height: 20px;
    }
    &__button {
      width: 100%;
      height: 60px;
      outline: none;
      border: none;
      box-shadow: none;
      border-radius: 5px;
      margin-bottom: 20px;
      background-color: #ececec;
      color: $black;
      font-size: 16px;
      line-height: 20px;
      &-download {
        img {
          margin-right: 15px;
        }
        margin-bottom: 22px;
        background-color: #464d77;
        color: $white;
      }
    }
  }
}
</style>
